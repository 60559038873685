import React from 'react'
import LayoutBlog from '../../components/layout-blog'
import SEO from '../../components/seo'

const BestCheapPhoneForDrone = () => {
  
  return (
    <LayoutBlog>
      <SEO />

      <a href="https://www.dji.com/downloads/products/mavic-mini">Mavic Mini</a>

      <a href="https://www.cnet.com/news/samsungs-note-8-display-sports-record-breaking-brightness/">Samsung Note 8</a>


    </LayoutBlog>
  )
}

export default BestCheapPhoneForDrone